import { useState } from "react";
import { useLocation, Link } from "react-router-dom";

export default (props) => {

    const location = useLocation();
    const SelectedMedicationObj = location.state?.SelectedMedicationObj;
    const [medicationViewModel, setMedicationViewModel] = useState(location.state?.SelectedMedicationObj);
    const medicationDetail = location.state?.medicationDetail;
    const pricesDetailPath = "/medications/" + location.state?.medicationDetail?.names[1];
    
    // console.log(medicationViewModel, pricesDetailPath);


    const handleChange = (e) => {

        // console.log(JSON.stringify(e.target.value));
       // this.setMedicationViewModel({ selectValue: e.target.value });
      }

    return (
        <>
            <section className="home_one">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="drugs_inner_best_deals">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3>{medicationDetail?.names[0]}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p className="fst-italic">{medicationDetail?.names[1]}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                        <h4>Update prescription</h4>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                        <Link to={`${pricesDetailPath}`} state={{ SelectedMedicationObj: SelectedMedicationObj, medicationDetail: medicationDetail }} >
                                            Cancel
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label>Label</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="dropdown">
                                            {/* <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                Lipitor (Brand)
                                            </button>
                                            <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="">Atorvastatin (generic)</a></li>
                                            </ul> */}
                                            <select value={medicationViewModel.id} onChange={handleChange}>
                                                {medicationDetail?.drugs?.map((option) => {
                                                  return  <option value={option.id}>{option?.name} ({option?.type})</option>
                                                })}

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label>Form</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                                Tablet
                                            </button>
                                            <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="">Tablet</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label>Dosage</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                10MG
                                            </button>
                                            <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="">80MG</a></li>
                                                <li><a className="dropdown-item" href="">40MG</a></li>
                                                <li><a className="dropdown-item" href="">20MG</a></li>
                                                <li><a className="dropdown-item" href="">10MG</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label>Quantity</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                6 Tablets
                                            </button>
                                            <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" href="">6 Tablets</a></li>
                                                <li><a className="dropdown-item" href="">15 Tablets</a></li>
                                                <li><a className="dropdown-item" href="">20 Tablets</a></li>
                                                <li><a className="dropdown-item" href="">30 Tablets</a></li>
                                                <li><a className="dropdown-item" href="">35 Tablets</a></li>
                                                <li><a className="dropdown-item" href="">Custom</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <a href="home 4.html">
                                <button className="btn save_prescription_btn">Save prescription</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}