
export const routeVariants = {
    initial: {
      y: "100vh",
    },
    final: {
      y: "0vh",
      transition: {
        type: "spring",
        mass: 0.5,
      },
    },
  };

  export const loginRouteVariants = {
    initial: {
      y: "-100vh",
    },
    final: {
      y: "0vh",
      transition: {
        type: "spring",
        mass: 0.5,
      },
    },
  };


  export const wrapperVariants = {
    hidden: {
      // opacity: 0,
      // x: '100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', delay: 0.1 },
    },
    exit: {
      x: '-100vh',
      transition: { ease: 'easeInOut' },
    },
  };
  export const squareVariants = {
    initial: {
      opacity: 0,
      scale: .3,
    },
    animate: {
      opacity: 1,
      scale: 1,
    }
  }