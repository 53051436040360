import React, { useEffect, useState, useRef } from 'react';


// import { useSelector } from 'react-redux';
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';
import { GoogleAPIKeyClient, GoogleAPIKeyDCD } from '../../environment';
import ownMarker from "../../assets/images/ownMarker.png"
import pharmacyMarker from "../../assets/images/pharmacyMarker.png"
// import { useGoogleMapsLoader } from "./googleMapsLoader";



const mapStyles = {
  width: '100%',
};

const mapOptions = {
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};

const michiganBounds = {
  north: 48.3043,
  east: -82.1226,
  south: 41.6962,
  west: -90.4180,
};

const MapContainer = (props) => {

  const [markers, setMarkers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const [mapCenter, setMapCenter] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [infoWindowIsVisible, setInfoWindowIsVisible] = useState(false);

  const [selectedPlace, setSelectedPlace] = useState(null);



  const { google, ownPosition, pharmacyList } = props;
  const mapRef = useRef(null);
  const markerRefs = useRef({});

  useEffect(() => {
    if (mapRef.current) {
      showAllInfoWindows();
      setInfoWindowIsVisible(true);
    }
  }, [mapRef.current, markers]);

  const showAllInfoWindows = () => {
    markers.forEach((marker) => {
      const markerRef = markerRefs.current[marker.id];
      if (markerRef) {
        const infoWindow = markerRef.infoWindow;
        if (infoWindow) {
          infoWindow.open(mapRef.current, markerRef);
        }
      }
    });
  };

  // const pharmacyList = useSelector((state) => state.drugPrices.drugPrices);

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }


  // useEffect(() => {
  //   // const { google } = props;
  //   const geocoder = new google.maps.Geocoder();

  //   const zipCode = ownPosition?.postalCode;

  //   geocoder.geocode({ address: zipCode }, (results, status) => {
  //     if (status === google.maps.GeocoderStatus.OK && results[0]) {
  //       // debugger;
  //       pharmacyList.map((pharmacy, index) => {
  //         setMarkers(prev => [{ id: index + 1, name: pharmacy.pharmacyName, lat: pharmacy.lat, lng: pharmacy.lng }, ...prev]);
  //         // markerRef.current.onClick()
  //       })
  //     }
  //   });
  // }, [google]);



  useEffect(() => {
    console.log({ pharmacyList })
    pharmacyList.map((pharmacy, index) => {
      if (pharmacy.userPharmacySeqNo) {
        setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
      }
      else
        setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.lat, lng: pharmacy.lng }, ...prev])
    })
    if (pharmacyList.length > 0) {
      setMapCenter({ lat: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].latitude : pharmacyList[0].lat, lng: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].longitude : pharmacyList[0].lng })
    }
    if (pharmacyList.length == 0 && !!ownPosition) {
      setMapCenter({ lat: ownPosition.lat, lng: ownPosition.lng })
    }
  }, [pharmacyList])



  useEffect(() => {
    pharmacyList.map((pharmacy, index) => {
      setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
    })
    if (pharmacyList.length > 0)
      setMapCenter({ lat: pharmacyList[0].latitude, lng: pharmacyList[0].longitude })
    if (pharmacyList.length == 0 && !!ownPosition) {
      setMapCenter({ lat: ownPosition.lat, lng: ownPosition.lng })
    }
  }, [])



  // const setMapBounds = () => {
  //   const { google } = props;
  //   const map = mapRef.current.map;
  //   const bounds = new google.maps.LatLngBounds();

  //   // Define the coordinates of Michigan's bounding box
  //   const north = 48.3043;
  //   const east = -82.1226;
  //   const south = 41.6962;
  //   const west = -90.4180;

  //   // Extend the bounds with the specified coordinates
  //   bounds.extend(new google.maps.LatLng(north, east));
  //   bounds.extend(new google.maps.LatLng(south, west));

  //   // Fit the map to the calculated bounds
  //   map.fitBounds(bounds);
  // };

  // useEffect(()=>{
  //   setMapBounds();
  // },[])



  return (
    <>
      {
        ownPosition?.showMap && isLoaded &&
        <Map
          google={google}
          zoom={12}
          style={mapStyles}
          initialCenter={{
            lat: ownPosition?.lat || 40.854885,
            lng: ownPosition?.lng || -88.081807,
          }}
          center={mapCenter}
          // initialCenter={{ lat: 44.3148, lng: -85.6024 }}
          fullscreenControl={false}
          mapTypeControl={false}
          zoomControl={false}
          // ref={mapRef}
          ref={(map) => (mapRef.current = map)}
          onReady={showAllInfoWindows}
        >
          <Marker
            title={'Your Location'}
            name={'My Location'}
            key={"My Location"}
            visible={true}
            // onClick={onMarkerClick}
            position={{ lat: ownPosition?.lat, lng: ownPosition?.lng }}
          />
          {
            markers.length > 0 && markers.map((marker, index) => {
              return (
                <Marker
                  title={marker.name}
                  name={marker.name}
                  key={index}
                  position={{ lat: marker.lat, lng: marker?.lng }}
                  icon={{
                    url: pharmacyMarker,
                    anchor: new google.maps.Point(32, 32),
                    scaledSize: new google.maps.Size(22, 22)
                  }}
                  onClick={(props, marker, e) => onMarkerClick(props, marker)}
                  ref={(ref) => (markerRefs.current[marker.id] = ref)}
                />
              )
            })
          }
          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow || infoWindowIsVisible}>
            <div>
              <span>{selectedPlace?.name}</span>
            </div>
          </InfoWindow>
        </Map>
      }
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: GoogleAPIKeyClient,
})(MapContainer);

// import React from 'react'
// import { GoogleMap, useJsApiLoader, Marker, useLoadScript  } from '@react-google-maps/api';
// // import { useGoogleMapsLoader } from "./googleMapsLoader";

// const containerStyle = {
//   width: '100%',
//   height: '100%',
//   position: "absolute"
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523
// };

// function MyComponent({ownPosition}) {


//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: GoogleAPIKeyClient,
//   });

//   const [map, setMap] = React.useState(null);

//   const mapOptions = {
//     zoom: 14,
//     center: { lat: 37.7749, lng: -122.4194 },
//     disableDefaultUI: true, // Hide the default controls
//   };

//   const onLoad = React.useCallback(function callback(map) {
//     // This is just an example of getting and using the map instance!!! don't just blindly copy!
//     const bounds = new window.google.maps.LatLngBounds(center);
//     map.fitBounds(bounds);

//     setMap(map)
//   }, [])

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null)
//   }, [])

//   const markers = [
//     { lat: 37.7749, lng: -122.4194 },
//     { lat: 37.7831, lng: -122.4039 },
//     { lat: 37.7749, lng: -122.3898 },
//     // Add more marker objects as needed
//   ];

//   return isLoaded ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={14}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//         options={mapOptions}
//       >
//         <Marker key="myLocation" position={{ lat: ownPosition.lat, lng: ownPosition.lng }} />
//         { /* Child components, such as markers, info windows, etc. */ }
//         {markers.map((marker, index) => (
//           <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} />
//         ))}
//         <></>
//       </GoogleMap>
//   ) : <></>
// }

// export default React.memo(MyComponent)

// import { GoogleMap, Marker } from "react-google-maps"
// import { useState, useEffect } from 'react';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

// const MyMapComponent = withScriptjs(withGoogleMap((props) => {
//   const [markers, setMarkers] = useState([]);
//   const [mapCenter, setMapCenter] = useState(null);
//   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);


//   const { ownPosition, pharmacyList } = props;




//   useEffect(() => {
//     console.log({ pharmacyList })
//     pharmacyList.map((pharmacy, index) => {
//       if (pharmacy.userPharmacySeqNo) {
//         setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
//       }
//       else
//         setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.lat, lng: pharmacy.lng }, ...prev])
//     })
//     if (pharmacyList.length > 0) {
//       debugger;
//       setMapCenter({ lat: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].latitude : pharmacyList[0].lat, lng: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].longitude : pharmacyList[0].lng })
//     }
//   }, [pharmacyList])



//   useEffect(() => {
//     pharmacyList.map((pharmacy, index) => {
//       setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
//     })
//     if (pharmacyList.length > 0)
//       setMapCenter({ lat: pharmacyList[0].latitude, lng: pharmacyList[0].longitude })
//   }, [])

//   console.log({markers})

//   const handleMarkerClick = (index) => {
//     setSelectedMarkerIndex(index);
//   };

//   const handleMarkerClose = () => {
//     setSelectedMarkerIndex(null);
//   };
//   console.log({ownPosition})

//   return <GoogleMap
//     defaultZoom={12}
//     // zoom={12}
//     defaultCenter={{ lat: props.ownPosition.lat, lng: props.ownPosition.lng }}
//     center={mapCenter}
//     defaultOptions={{
//       disableDefaultUI: true,
//       // gestureHandling: 'none',
//       // This will disable zooming and panning gestures
//     }}
//   >
//     <Marker position={{ lat: ownPosition.lat, lng: ownPosition.lng }} />
//     {props.isMarkerShown && markers.length > 0 && markers.map((mark, index) => {
//       return (
//         <Marker position={{ lat: mark.lat, lng: mark.lng }} key={index} onClick={()=> handleMarkerClick(index)}>
//           {selectedMarkerIndex === index && (
//             <InfoWindow onCloseClick={() => handleMarkerClose()}>
//               <div>
//                 <h6>{mark.name}</h6>
//                 {/* <p>{marker.description}</p> */}
//               </div>
//             </InfoWindow>
//           )}
//           </Marker>
//       )
//     })}
//   </GoogleMap>
// }
// ))

// export default MyMapComponent;


// import React, { useState, useEffect } from "react";
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div><img src={pharmacyMarker} style={{ height: 20, width: 20 }} />{text}</div>;

// export default function SimpleMap(props) {

//   const [markers, setMarkers] = useState([]);
//   const [mapCenter, setMapCenter] = useState(null);
//   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);

//   const { ownPosition, pharmacyList } = props;

//   useEffect(() => {
//     console.log({ pharmacyList })
//     pharmacyList.map((pharmacy, index) => {
//       if (pharmacy.userPharmacySeqNo) {
//         setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
//       }
//       else
//         setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.lat, lng: pharmacy.lng }, ...prev])
//     })
//     if (pharmacyList.length > 0) {
//       setMapCenter({ lat: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].latitude : pharmacyList[0].lat, lng: pharmacyList[0].userPharmacySeqNo ? pharmacyList[0].longitude : pharmacyList[0].lng })
//     }
//   }, [pharmacyList])



//   useEffect(() => {
//     pharmacyList.map((pharmacy, index) => {
//       setMarkers(prev => [{ name: pharmacy.pharmacyName, lat: pharmacy.latitude, lng: pharmacy.longitude }, ...prev])
//     })
//     if (pharmacyList.length > 0)
//       setMapCenter({ lat: pharmacyList[0].latitude, lng: pharmacyList[0].longitude })
//   }, [])

//   console.log({ markers })

//   const handleMarkerClick = (index) => {
//     setSelectedMarkerIndex(index);
//   };

//   const handleMarkerClose = () => {
//     setSelectedMarkerIndex(null);
//   };
//   const defaultProps = {
//     center: {
//       lat: 10.99835602,
//       lng: 77.01502627
//     },
//     zoom: 11
//   };

//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '100vh', width: '100%' }}>
//       <GoogleMapReact
//         // bootstrapURLKeys={{ key: "" }}
//         defaultCenter={{ lat: props.ownPosition.lat, lng: props.ownPosition.lng }}
//         defaultZoom={12}
//         center={mapCenter}
//       >
//         <AnyReactComponent
//           lat={props.ownPosition.lat}
//           lng={props.ownPosition.lng}
//           text="My Marker"
//         />
//         {markers.length > 0 && markers.map((mark, index) => {
//           return (
//             <AnyReactComponent
//               lat={mark.lat}
//               lng={mark.lng}
//               text={mark.name}
//             />
//           )
//         })}
//       </GoogleMapReact>
//     </div>
//   );
// }








