import logo from "../assets/images/logo.png"; // for Rxlink
import upennLogo from "../assets/images/PennMedicine.png"; // for PennMedicine
import papajohnsLogo from "../assets/images/papajones-logo.png"; // for papaJohns
import tripmentLogo from "../assets/images/tripment.png"; // for tripment]
import houstonLogo from "../assets/images/houston-health-logo.png"; // for houston-health
import lcmchealthlogo from "../assets/images/LCMC_Health_Logo.png"; // for lcmc
import chnwLogo from "../assets/images/community_health_logo.png";// for community health network
import nclogo from "../assets/images/Norton_clark.png";
import dmhlogo from "../assets/images/decaturmorgan.png";
import memorialLogo from "../assets/images/RxLink assets/memorial.jpg";
import mhslogo from "../assets/images/mhs-logo.png";
import prismalogo from "../assets/images/prisma.png";
import mdsxlogo from "../assets/images/msdx-logo.png";
import uiHealth from "../assets/images/Health_primary_logo.png";
import lasPalmasLogo from "../assets/images/las_palmas.PNG";
import stDavidsLogo from "../assets/images/st_davids.PNG";
import medicalCenterLogo from "../assets/images/Medical_Center.jpg";

export function getDomainName() {

    const isUpenn = window.location.href.includes("upenn");
    const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
    const isTripment = window.location.href.includes("tripment");
    const isHouston = window.location.href.includes("hhc");
    const isLcmc = window.location.href.includes("lcmc");
    const isNC = window.location.href.includes("clark");
    const isCHNW = window.location.href.includes("chnw");
    const isDecaturMorgan = window.location.href.includes("decaturmorganhospital");
    const isMethodist = window.location.href.includes("methodist") || window.location.href.includes("medsavings");
    const isPrisma = window.location.href.includes("prismahealth");
    const isMDSX = window.location.href.includes("middlesexhealth");
    const isUIHealth = window.location.href.includes("uihealth");
    const isLasPalmas = window.location.href.includes("laspalmasdelsolhealthcare");
    const isStDavids = window.location.href.includes("stdavidshealthcare");
    const isMedicalCenter = window.location.href.includes("osumc");



    const isProd = process.env.REACT_APP_ENV === "PRODUCTION" ? true : false;


    const domainName = isUpenn ? "Upenn" : isPapaJohn ? "PapaJohns" : isTripment ? "Tripment" : isHouston ? "HHC" : isLcmc ? "LCMC" : isNC ? "Clark" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMHCare" : isMethodist ? "HCACare" : isPrisma ? "PrismaCare" : isMDSX ? "MiddlesexCare" : isUIHealth ? "UIHCare" : isLasPalmas ? "SOLCare" : isStDavids ? "STDVCare" : isMedicalCenter ? "OSUCare" : "Clinical";

    const pid = isUpenn ? "UPENN" : isPapaJohn ? "PAPAJOHNS" : isHouston ? "HHC" : isTripment ? "TRIPMENT" : isLcmc ? "LCMC" : isNC ? "NCH" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMH" : isMethodist ? "HCACare" : isPrisma ? "PRIS" : isMDSX ? "MID" : isUIHealth ? "UIH" : isLasPalmas ? "CIPHER_SOL" : isStDavids ? "CIPHER_STDV" : isMedicalCenter ? "CIPHER_OSU" : "RXLINK";

    const defaultLogo = isUpenn ? upennLogo : isPapaJohn ? papajohnsLogo : isHouston ? houstonLogo : isTripment ? tripmentLogo : isLcmc ? lcmchealthlogo : isNC ? nclogo : isCHNW ? chnwLogo : isDecaturMorgan ? dmhlogo : isMethodist ? mhslogo : isPrisma ? prismalogo : isMDSX ? mdsxlogo : isUIHealth ? uiHealth : isLasPalmas ? lasPalmasLogo : isStDavids ? stDavidsLogo : isMedicalCenter ? medicalCenterLogo : isProd ? nclogo : logo;

    const healthSystem = isUpenn ? "Upenn Medicine" :
        isPapaJohn ? "Papa John's United" :
            isTripment ? "Tripment Health" :
                isHouston ? "Housten Health System" :
                    isLcmc ? "LCMC Health" : isNC ? "Norton Clark" : isCHNW ? "Community Health Network" :
                        isDecaturMorgan ? "Decatur Morgan Hospital" : isMethodist ? "Methodist" :
                            isPrisma ? "Prisma Health" :
                                isMDSX ? "Middlesex Health" : isUIHealth ? "University of Illinois Health" : isLasPalmas ? "Las Palmas del Sol Healthcare" : isStDavids ? "St David’s HealthCare" : isMedicalCenter ? "OSU Medical Center" : "RxLink";

    return {
        pid,
        domainName,
        defaultLogo,
        healthSystem
    };
}